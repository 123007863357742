import React from 'react';
import './Home.css';
import home1 from "../../assets/images/crousel/home-1.jpg";
import home2 from "../../assets/images/crousel/home-2.jpg";
import home3 from "../../assets/images/crousel/home-3.jpg";
import team1 from "../../assets/images/crousel/team-1.jpg";
import team2 from "../../assets/images/crousel/team-2.jpg";
import team3 from "../../assets/images/crousel/team-3.jpg";
import project1 from "../../assets/images/crousel/project-1.jpg";
import project2 from "../../assets/images/crousel/project-2.jpg";
import project3 from "../../assets/images/crousel/project-3.jpg";

const Home = () => (
  <>
    <section id="carouselExampleDark" className="position-relative overflow-hidden carousel slide">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="10000">
          <img src={home1} className="d-block w-100" alt="..." />
          <div className="carousel-caption">
            <h5>First slide label</h5>
            <p>Some representative placeholder content for the first slide.</p>
            <p><a href='#' className='btn btn-warning mt-3'>Learn More</a></p>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={home2} className="d-block w-100" alt="..." />
          <div className="carousel-caption">
            <h5>Second slide label</h5>
            <p>Some representative placeholder content for the second slide.</p>
            <p><a href='#' className='btn btn-warning mt-3'>Learn More</a></p>
          </div>
        </div>
        <div className="carousel-item">
          <img src={home3} className="d-block w-100" alt="..." />
          <div className="carousel-caption">
            <h5>Third slide label</h5>
            <p>Some representative placeholder content for the third slide.</p>
            <p><a href='#' className='btn btn-warning mt-3'>Learn More</a></p>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </section>

    <section className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
      <div className="col-md-5 p-lg-5 mx-auto my-5">
        <h1 className="display-4 fw-normal">Punny headline</h1>
        <p className="lead fw-normal">And an even wittier subheading to boot. Jumpstart your marketing efforts with this example based on Apple’s marketing pages.</p>
        <a className="btn btn-danger" href="/#">Coming soon</a>
      </div>
      <div className="product-device shadow-sm d-none d-md-block"></div>
      <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
    </section>
    
    <div className="container">
      <section className="row featurette">
        <div className="col-lg-4 text-center">
          <img className="bd-placeholder-img rounded-circle" src={team1} width="140" />
          <h2>Heading</h2>
          <p>Some representative placeholder content for the three columns of text below the carousel. This is the first column.</p>
          <p><a className="btn btn-warning" href="/#">View details &raquo;</a></p>
        </div>
        <div className="col-lg-4 text-center">
        <img className="bd-placeholder-img rounded-circle" src={team2} width="140" />

          <h2>Heading</h2>
          <p>Another exciting bit of representative placeholder content. This time, we've moved on to the second column.</p>
          <p><a className="btn btn-warning" href="/#">View details &raquo;</a></p>
        </div>
        <div className="col-lg-4 text-center">
        <img className="bd-placeholder-img rounded-circle" src={team3} width="140" />

          <h2>Heading</h2>
          <p>And lastly this, the third column of representative placeholder content.</p>
          <p><a className="btn btn-warning" href="/#">View details &raquo;</a></p>
        </div>
      </section>

      <hr className="featurette-divider"></hr>

      <div className="row p-3">
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="card text-dark text-center pb-2">
            <div className="card-body">
              <i className="fa fa-bolt"></i>
              <h3 className="card-title">Dark card title</h3>
              <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
              <button className='btn btn-warning text-dark'>Read More</button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="card text-dark text-center pb-2">
            <div className="card-body">
            <i className="fa fa-cloud"></i>
              <h3 className="card-title">Dark card title</h3>
              <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
              <button className='btn btn-warning text-dark'>Read More</button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="card text-dark text-center pb-2">
            <div className="card-body">
              <i className="fa fa-wand-magic-sparkles"></i>
              <h3 className="card-title">Dark card title</h3>
              <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
              <button className='btn btn-warning text-dark'>Read More</button>
            </div>
          </div>
        </div>
      </div>
    
      <hr className="featurette-divider"></hr>

      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">First featurette heading. <span className="text-muted">It’ll blow your mind.</span></h2>
          <p className="lead">Some great placeholder content for the first featurette here. Imagine some exciting prose here.</p>
        </div>
        <div className="col-md-5">
        <img className="bd-placeholder-img rounded-circle" src={project1} width="400" />

        </div>
      </div>

      <hr className="featurette-divider"></hr>

      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading">Oh yeah, it’s that good. <span className="text-muted">See for yourself.</span></h2>
          <p className="lead">Another featurette? Of course. More placeholder content here to give you an idea of how this layout would work with some actual real-world content in place.</p>
        </div>
        <div className="col-md-5 order-md-1">
        <img className="bd-placeholder-img rounded-circle" src={project2} width="400" />

        </div>
      </div>

      <hr className="featurette-divider"></hr>

      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">And lastly, this one. <span className="text-muted">Checkmate.</span></h2>
          <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
        </div>
        <div className="col-md-5">
        <img className="bd-placeholder-img rounded-circle" src={project3} width="400" />

        </div>
      </div>

      <hr className="featurette-divider"></hr>

      

      

      <div className="row p-3">
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="card text-dark text-center pb-2">
            <img src={project1} className="card-img-top" alt="..." />
            <div className="card-body">
              <h3 className="card-title">Dark card title</h3>
              <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
              <button className='btn btn-warning text-dark'>Read More</button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="card text-dark text-center pb-2">
            <img src={project2} className="card-img-top" alt="..." />
            <div className="card-body">
            <h3 className="card-title">Dark card title</h3>
              <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
              <button className='btn btn-warning text-dark'>Read More</button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-4 mb-2">
          <div className="card text-dark text-center pb-2">
            <img src={project3} className="card-img-top" alt="..." />
            <div className="card-body">
              <h3 className="card-title">Dark card title</h3>
              <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
              <button className='btn btn-warning text-dark'>Read More</button>
            </div>
          </div>
        </div>
      </div>
    
      <hr className="featurette-divider"></hr>

      
    </div>
  </>
);

export default Home;
