import React from 'react';
import './AboutUs.css';
import aboutus from "../../assets/images/about-us.jpg";

const AboutUs = () => (
  <section className="py-3 py-md-5 aboutus">
    <div className="container">
      <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
        <div className="col-12 col-lg-6 col-xl-5">
          <img className="img-fluid rounded" loading="lazy" src={aboutus} alt="About 1" />
        </div>
        <div className="col-12 col-lg-6 col-xl-7">
          <div className="row justify-content-xl-center">
            <div className="col-12 col-xl-11">
              <h2 className="mb-3">Who Are We?</h2>
              <p className="lead fs-4 text-secondary mb-3">We help people to build incredible brands and superior products. Our perspective is to furnish outstanding captivating services.</p>
              <p className="mb-5">We are a fast-growing company, but we have never lost sight of our core values. We believe in collaboration, innovation, and customer satisfaction. We are always looking for new ways to improve our products and services.</p>
              <div className="row gy-4 gy-md-0 gx-xxl-5X">
                <div className="col-12 col-md-6">
                  <div className="d-flex">
                    <div className="me-4 fs-2 text-warning">
                      <i class="fa-solid fa-globe"></i>
                    </div>
                    <div>
                      <h2 className="h4 mb-3">Versatile Brand</h2>
                      <p className="text-secondary mb-0">We are crafting a digital method that subsists life across all mediums.</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-flex">
                    <div className="me-4 fs-2 text-warning">
                      <i class="fa-regular fa-comment"></i>
                    </div>
                    <div>
                      <h2 className="h4 mb-3">Digital Agency</h2>
                      <p className="text-secondary mb-0">We believe in innovation by merging primary with elaborate ideas.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUs;
