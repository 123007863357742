import React from 'react';
import './NotFound.css';

const NotFound = () => (
  <section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <h3 className="h2 mb-2">Oops! You're lost.</h3>
            <p className="mb-5">The page you are looking for was not found.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default NotFound;
