import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const LandingPageLayout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />  
        </>
    );
}

export default LandingPageLayout;